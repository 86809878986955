import styles from "./index.module.css"

const CarouselButton = ({ prev, next }) => {
  return (
    <div>
      <button
        className={styles.main__nav_prev}
        type="button"
        onClick={() => prev()}
      >
        <svg
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="22.3677"
            cy="22.9785"
            r="22"
            transform="rotate(-180 22.3677 22.9785)"
            fill="#051006"
          />
          <circle
            cx="22.3677"
            cy="22.9785"
            r="21.5"
            transform="rotate(-180 22.3677 22.9785)"
            stroke="white"
            strokeOpacity="0.33"
          />
          <path
            d="M25.3677 16.9785L19.3677 22.9785L25.3677 28.9785"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <button
        className={styles.main__nav_next}
        type="button"
        onClick={() => next()}
      >
        <svg
          width="45"
          height="45"
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="22.3677" cy="22.9785" r="22" fill="#051006" />
          <circle
            cx="22.3677"
            cy="22.9785"
            r="21.5"
            stroke="white"
            strokeOpacity="0.33"
          />
          <path
            d="M19.3677 28.9785L25.3677 22.9785L19.3677 16.9785"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default CarouselButton;
