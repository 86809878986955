/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import {
  Routes,
  Route,
  Outlet,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import Web3 from "web3";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";
import Overview from "./overview";
import Currencies from "./currencies";
import DigitalItems from "./digitalItems";
import SendReceive from "./sendRecevie";
import Transactions from "./transactions";
import Offers from "./offers";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { getTicketsFromNFTs } from "../../../api/event";
import { getUserToken } from "../../../api/token";
import { getOffer } from "../../../api/offer";
import { ITicket, IToken } from "../../../utils/interface";
import { bnbPriceABI, bnbPriceAddress } from "../../../utils/nft_contract";
import { isWalletValid } from "../../../utils";
import { defaultTokens } from "../../../utils/default_tokens";
import styles from "./index.module.css";

const PageWallet = () => {
  const location = useLocation();
  const {
    setLoading,
    setDigitalItems,
    setTokens,
    setBalance,
    setBnbPrice,
    setTransactions,
    setErc20Transactions,
    setErc721Transactions,
    offers,
    setOffers,
  } = useAppContext();
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const wallet = useMemo(() => {
    return userInfo?.user?.wallet_address;
  }, [userInfo]);
  const path = location.pathname;
  const fetchNfts = async () => {
    setLoading(true);
    axios
      .get(
        "https://deep-index.moralis.io/api/v2/" +
          wallet +
          "/nft?chain=bsc&format=decimal",
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": process.env.REACT_APP_MORALIS as string,
          },
        }
      )
      .then(async (response) => {
        let tokenURLs = [];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          tokenURLs.push(
            "https://bscscan.com/token/" +
              element.token_address +
              "?a=" +
              element.token_id
          );
        }
        const res = await getTicketsFromNFTs({ tokenURLs });
        const selected = res.tickets.map((obj: any) => obj.tokenURL);
        const data = selected.map((url: any) => {
          const parts = url.split("/");
          const lastPart = parts[parts.length - 1];
          const $address = lastPart.split('=')[0];
          const address = $address.substring(0, $address.length - 2);
          const tokenId = lastPart.split("=")[1];
          return { address, tokenId };
        });
        const result = await getOffer({ data });
        setOffers(result);
        sortTickets(res.tickets, result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoading(false);
      });
  };

  const sortTickets = (array: [], result: []) => {
    let data = array.sort(
      (item1: ITicket, item2: ITicket) =>
        new Date(item2.createdAt).getTime() -
        new Date(item1.createdAt).getTime()
    );
    let $data = [];
    for (let index = 0; index < data.length; index++) {
      let element: any = data[index];
      const parts = element.tokenURL.split("/");
      const lastPart = parts[parts.length - 1];
      const $address = lastPart.split('=')[0];
      const address = $address.substring(0, $address.length - 2);
      const tokenId = lastPart.split("=")[1];
      let offers = [];
      for (let i = 0; i < result.length; i++) {
        const offer: any = result[i];
        if (offer.address.toLowerCase() === address.toLowerCase() && offer.tokenId === tokenId) {
          offers.push(offer);
        }
      }
      element.offers = offers;
      $data.push(element);
    }
    setDigitalItems($data);
  };

  const getAllTokens = async () => {
    const userTokens = await getUserToken({ userId: userInfo?.user?.id });
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = wallet;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.token.getWalletTokenBalances({
      address,
      chain,
    });
    const data = response.toJSON();
    const promises = data.map((item: any) => {
      if (!item.possible_spam && item.verified_contract) {
        return new Promise((resolve, reject) => {
          const res = Moralis.EvmApi.token.getTokenPrice({
            address: item.token_address,
            chain: EvmChain.BSC,
          });
          resolve(res);
        });
      } else {
        return item;
      }
    });
    let tokenData: IToken[] = [];
    await Promise.all(promises)
      .then((results) => {
        console.log("All promises resolved:", results);
        results.forEach((element: any, index: number) => {
          tokenData.push({
            ...data[index],
            balance: Number(
              (
                Number(data[index].balance) /
                10 ** data[index].decimals
              ).toFixed(6)
            ),
            price: !element?.balance ? element?.toJSON()?.usdPrice : 0,
          });
        });
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
    const allTokens = defaultTokens.concat(userTokens);
    const balancedUserTokens = allTokens.map((item1: any) => {
      const matchingItem = tokenData.find(
        (item2) =>
          item2.token_address.toLowerCase() ===
          item1.token_address.toLowerCase()
      );
      return {
        ...item1,
        balance: matchingItem ? matchingItem?.balance : 0,
        price: matchingItem ? matchingItem?.price : 0,
      };
    });
    setTokens(balancedUserTokens);
  };

  const fetchBalance = async () => {
    const web3 = new Web3("https://bsc-dataseed.binance.org/");
    const balanceAmount = await web3.eth.getBalance(
      userInfo.user.wallet_address
    );
    setBalance(Number(BigInt(balanceAmount)) / 1000000000000000000);
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed.binance.org"
    );
    const bnbPriceContract = new ethers.Contract(
      bnbPriceAddress,
      bnbPriceABI,
      provider
    );
    const bnbPrice = await bnbPriceContract.latestAnswer();
    setBnbPrice(bnbPrice.toNumber() / 100000000);
  };

  const getTransactions = async () => {
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = wallet;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.transaction.getWalletTransactionsVerbose(
      {
        address,
        chain,
      }
    );
    const result = response.toJSON().result;
    setTransactions(result);
  };

  const getERC20Transfer = async () => {
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = wallet;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.token.getWalletTokenTransfers({
      address,
      chain,
    });

    const result = response.toJSON().result;
    setErc20Transactions(result);
  };

  const getERC721Transfer = async () => {
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = wallet;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.nft.getWalletNFTTransfers({
      address,
      chain,
    });

    const result = response.toJSON().result;
    setErc721Transactions(result);
  };

  useEffect(() => {
    if (isWalletValid(wallet)) {
      fetchNfts();
      fetchBalance();
      getAllTokens();
      getTransactions();
      getERC20Transfer();
      getERC721Transfer();
    }
  }, [wallet]);
  useEffect(() => {
    if (!userInfo) {
      navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container wallet__container">
      <div className={styles.side_menu}>
        <p className={styles.assets_title}>{t("my assets")}</p>
        <Link to="/wallet">
          <div
            className={
              path === "/wallet"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/overview.svg"
              className={styles.assets__menu_img}
              alt="overview"
            />
            <p className={styles.menu__item_text}>{t("overview")}</p>
          </div>
        </Link>
        <Link to="/wallet/digital_items">
          <div
            className={
              path === "/wallet/digital_items"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/NFTs.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>{t("tickets & items")}</p>
          </div>
        </Link>
        <Link to="/wallet/offers">
          <div
            className={
              path === "/wallet/offers"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/offers.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>{t("offers")}</p>
            <div className={styles.red_offer}>
              <span>{offers.length}</span>
            </div>
          </div>
        </Link>
        <Link to="/wallet/send_receive">
          <div
            className={
              path === "/wallet/send_receive"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/send.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>{t("send & receive")}</p>
          </div>
        </Link>
        <Link to="/wallet/transaction">
          <div
            className={
              path === "/wallet/transaction"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/transaction.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>{t("transactions")}</p>
          </div>
        </Link>

        <Link to="/wallet/currencies">
          <div
            className={
              path === "/wallet/currencies"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/currencies.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>{t("currencies")}</p>
          </div>
        </Link>
      </div>
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="currencies" element={<Currencies />} />
        <Route path="digital_items" element={<DigitalItems />} />
        <Route path="send_receive" element={<SendReceive />} />
        <Route path="transaction" element={<Transactions />} />
        <Route path="offers" element={<Offers />} />
        <Route path="*" element={<Outlet />} />
      </Routes>
    </div>
  );
};

export default PageWallet;
