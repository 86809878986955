import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const BigHero = () => {
  // const { t } = useTranslation();
  return (
    <div className={styles.early__bird_home}>
      <Link
        to={`collection/${process.env.REACT_APP_HERO_2}`}
        className={styles.big_hero}
      >
        <img
          className={styles.big__hero_mobile}
          alt="Hero"
          src="/img/hero/troy_mobile.jpg"
        />
        <img
          className={styles.big__hero_desk}
          alt="Hero"
          src="/img/hero/troy_desk.jpg"
        />
        <div className={styles.early__bird_bottom}>
          <p className={styles.early__bird_text}>
            {/* <span className={styles.early__bird_date}>{t("buy")} →</span> */}
            <span className={styles.early__bird_date}>Usa tour tickets coming soon</span>
          </p>
        </div>
      </Link>
    </div>
  );
};

export default BigHero;
