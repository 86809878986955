import React, { useState } from "react";
import { useCookies, withCookies } from "react-cookie";
import { useMediaQuery } from "usehooks-ts";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import Notification from "../../../components/notification";
import styles from "./index.module.css";

const Header = ({ scrollItem }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { userInfo, setUserInfo } = useUserContext();
  const { searchStr, setSearchStr } = useAppContext();
  const [menuOn, setMenuOn] = useState(false);
  const [searchOn, setSearchOn] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const handleSignout = () => {
    setUserInfo(null);
    // removeCookie("userInfo");
    Cookies.remove("userInfo");
    navigate("/");
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(e.target.value);
    navigate("/explorer");
  };

  return (
    <header className={styles.header} ref={scrollItem}>
      <div className={styles.header__content}>
        <div className={styles.header__logo}>
          <Link to="/">
            <img src="/img/liveticketlogowhite.png" alt="data" />
          </Link>
        </div>
        {!isMobile && (
          <div className={styles.header__subcontainer}>
            <div className="header__search top__header_search">
              <input
                type="text"
                placeholder={t("search items, collections, and creators")}
                value={searchStr}
                onChange={onSearch}
                onFocus={() => navigate("/explorer")}
              />
              <button type="button" onClick={() => navigate("/explorer")}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
                </svg>
              </button>
            </div>

            <ul className={styles.header__nav}>
              <li className={styles.header__nav_item}>
                <Link to="/explorer">
                  <div id={"explorer"} className={styles.header__nav_link}>
                    <img src="/img/icons/explore.svg" alt="data" />
                    <p className={styles.search__visible}> {t("explore")}</p>
                  </div>
                </Link>
              </li>
              <li className={styles.header__nav_item}>
                <Link to="/blog">
                  <div id={"blog"} className={styles.header__nav_link}>
                    <img src="/img/icons/news.svg" alt="data" />
                    <p className={styles.search__visible}>{t("news")}</p>
                  </div>
                </Link>
              </li>
              <li className={styles.header__nav_item}>
                <Notification />
              </li>
              {!userInfo && (
                <li className={styles.header__nav_item}>
                  {/* <Link to="/Signin">
                    <div className={styles.header__nav_link}>
                      <img src="/img/icons/user.svg" alt="data" />
                      <p>{t("sign in")}</p>
                    </div>
                  </Link> */}
                  <>
                    <a
                      role="button"
                      href="/#"
                      id="dropdownSignMenu"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className={styles.header__nav_link}>
                        <img src="/img/icons/user.svg" alt="data" />
                        <p>{t("sign in")}</p>
                      </div>
                    </a>
                    <ul
                      className="dropdown-menu header__profile-menu"
                      aria-labelledby="dropdownSignMenu"
                    >
                      <li className={styles.sign__menu}>
                        <Link to="/signin">
                          <span>{t("sign in")}</span>
                        </Link>
                      </li>
                      <li className={styles.sign__menu}>
                        <span className={styles.create__account_txt}>
                          Create account
                        </span>
                      </li>
                      <li className={styles.sign__menu}>
                        <Link to="/signup">
                          &#183;
                          <span style={{ paddingLeft: 10 }}>
                            {t("personal")}
                          </span>
                        </Link>
                      </li>
                      <li className={styles.sign__menu}>
                        <Link to="/business-signup">
                          &#183;
                          <span style={{ paddingLeft: 10 }}>
                            {t("business")}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </>
                </li>
              )}
            </ul>
            {userInfo && (
              <div className={styles.header__actions}>
                <div
                  className={styles.header__action}
                  style={{ width: "auto" }}
                >
                  <>
                    <a
                      className={styles.header__profile_btn}
                      href="/#"
                      role="button"
                      id="dropdownMenuProfile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {userInfo.user.avatar ? (
                        <img src={`${userInfo.user.avatar}`} alt="data" />
                      ) : (
                        <img src="/img/avatars/avatar5.jpg" alt="data" />
                      )}

                      <div>
                        <div className={styles.header__username_contain}>
                          <p className={styles.header__username}>
                            {userInfo.user.name.split(" ")[0]}
                          </p>
                          <img
                            className={styles.header__verified}
                            src="/img/verified.svg"
                            alt="verified"
                          />
                        </div>
                        {(userInfo.user.user_type === "ADMIN" ||
                          userInfo.user.user_type === "BUSINESS") && (
                          <span className={styles.header__admin_user}>
                            {userInfo.user.user_type === "ADMIN"
                              ? t("admin user")
                              : userInfo.user.user_type === "CASHIER ADMIN"
                              ? "CASHINER"
                              : userInfo.user.user_type === "BUSINESS"
                              ? t("business")
                              : t("normal user")}
                          </span>
                        )}
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z" />
                      </svg>
                    </a>

                    <ul
                      className="dropdown-menu header__profile-menu"
                      aria-labelledby="dropdownMenuProfile"
                    >
                      <li>
                        <Link to="/wallet">
                          <img src="/img/icons/wallet.svg" alt="data" />
                          <span>{t("my assets")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/liked">
                          <img src="/img/icons/liked_white.svg" alt="data" />
                          <span>{t("liked")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/author">
                          <img src="/img/icons/user.svg" alt="data" />
                          <span>{t("profile settings")}</span>
                        </Link>
                      </li>

                      {userInfo.user.user_type === "ADMIN" && !isMobile && (
                        <>
                          <li className={styles.border__top_item}>
                            <Link to="/admin/settings">
                              <img src="/img/icons/settings.svg" alt="data" />
                              <span>{t("admin settings")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/admin/report">
                              <img
                                src="/img/icons/bar-chart-2.svg"
                                alt="data"
                              />
                              <span>{t("admin report")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/track/report">
                              <img
                                src="/img/icons/bar-chart-2.svg"
                                alt="data"
                              />
                              <span>{t("track report")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/sales/overview">
                              <img
                                src="/img/icons/bar-chart-2.svg"
                                alt="data"
                              />
                              <span>{t("sales reports")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/admin/users">
                              <img src="/img/icons/users.svg" alt="data" />
                              <span>{t("see users")}</span>
                            </Link>
                          </li>
                          <li className={styles.border__top_item}>
                            <Link to="/article/create">
                              <img
                                src="/img/icons/plus-circle.svg"
                                alt="data"
                              />
                              <span>{t("new article")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/collection/create">
                              <img
                                src="/img/icons/plus-circle.svg"
                                alt="data"
                              />
                              <span>{t("new collection")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/notification/create">
                              <img
                                src="/img/icons/plus-circle.svg"
                                alt="data"
                              />
                              <span>{t("new notification")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/message/create">
                              <img
                                src="/img/icons/plus-circle.svg"
                                alt="data"
                              />
                              <span>{t("new email")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/offer">
                              <img
                                src="/img/icons/plus-circle.svg"
                                alt="data"
                              />
                              <span>{t("new offer")}</span>
                            </Link>
                          </li>

                          <li className={styles.border__top_item}>
                            <Link to="/verify-event">
                              <img
                                src="/img/icons/check-circle.svg"
                                alt="data"
                              />
                              <span>{t("verify event")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog/verify">
                              <img
                                src="/img/icons/check-circle.svg"
                                alt="data"
                              />
                              <span>{t("verify article")}</span>
                            </Link>
                          </li>
                        </>
                      )}

                      {userInfo.user.user_type === "BUSINESS" &&
                        userInfo.user.business_allow &&
                        !isMobile && (
                          <>
                            <li>
                              <Link to="/admin/report">
                                <img
                                  src="/img/icons/bar-chart-2.svg"
                                  alt="data"
                                />
                                <span>{t("admin report")}</span>
                              </Link>
                            </li>
                            <li className={styles.border__top_item}>
                              <Link to="/sales/overview">
                                <img
                                  src="/img/icons/bar-chart-2.svg"
                                  alt="data"
                                />
                                <span>{t("sales reports")}</span>
                              </Link>
                            </li>
                            <li className={styles.border__top_item}>
                              <Link to="/article/create">
                                <img
                                  src="/img/icons/plus-circle.svg"
                                  alt="data"
                                />
                                <span>{t("new article")}</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/collection/create">
                                <img
                                  src="/img/icons/plus-circle.svg"
                                  alt="data"
                                />
                                <span>{t("new collection")}</span>
                              </Link>
                            </li>
                          </>
                        )}
                      {userInfo.user.email ===
                        process.env.REACT_APP_PERMISSION && (
                        <li>
                          <Link to="/permissions">
                            <img src="/img/icons/check.svg" alt="data" />
                            <span>{t("permission")}</span>
                          </Link>
                        </li>
                      )}
                      <li className={styles.border__top_item}>
                        <a role="button" href="/#" onClick={handleSignout}>
                          <img src="/img/icons/log-out-white.svg" alt="data" />
                          <span>{t("sign out")}</span>
                        </a>
                      </li>
                    </ul>
                  </>
                </div>
              </div>
            )}
          </div>
        )}

        {isMobile && (
          <div className={styles.header__mobile_menu}>
            <ul className={styles.header__nav}>
              <li className={styles.header__nav_item}>
                <div
                  id={"search"}
                  className={styles.header__nav_link}
                  onClick={() => setSearchOn(true)}
                >
                  <img src="/img/icons/search.svg" alt="data" />
                </div>
              </li>
              <li className={styles.header__nav_item}>
                <Notification />
              </li>
              <li className={styles.header__nav_item}>
                <div
                  id={"menu"}
                  className={styles.header__nav_link}
                  onClick={() => {
                    setMenuOn(true);
                  }}
                >
                  <img src="/img/icons/menu.svg" alt="data" />
                </div>
              </li>
            </ul>
            {menuOn && (
              <div className={styles.header__menu_mobile}>
                <div className={styles.header__menu_top}>
                  {userInfo ? (
                    <div className={styles.header__menu_user}>
                      <div className={styles.header__menu_user__logo}>
                        {userInfo.user.avatar ? (
                          <img src={`${userInfo.user.avatar}`} alt="data" />
                        ) : (
                          <img src="/img/avatars/avatar5.jpg" alt="data" />
                        )}
                      </div>
                      <div className="colum_start">
                        <div className={styles.header__menu_user__info}>
                          <p className={styles.header__menu_user__name}>
                            {userInfo.user.name.split(" ")[0]}
                          </p>
                          <img src="/img/icons/verified.svg" alt="data" />
                        </div>
                        {(userInfo.user.user_type === "ADMIN" ||
                          userInfo.user.user_type === "BUSINESS") && (
                          <p className={styles.header__menu_user__type}>
                            {userInfo.user.user_type === "ADMIN"
                              ? t("admin user")
                              : userInfo.user.user_type === "BUSINESS"
                              ? t("business")
                              : t("normal user")}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <img
                      src="/img/backstage_logo_horizontal_dark_background.svg"
                      alt="data"
                    />
                  )}
                  <button
                    onClick={() => setMenuOn(false)}
                    className={styles.btn_close}
                  >
                    <img
                      src="/img/icons/close-icon.svg"
                      alt="data"
                      width={24}
                      height={24}
                    />
                  </button>
                </div>
                {/* Menu Item Lists */}
                <div className={styles.header__menu_items}>
                  <div
                    className={styles.header__menu_item}
                    onClick={() => {
                      setMenuOn(false);
                      navigate("/explorer");
                    }}
                  >
                    <div className={styles.header__menu_item__container}>
                      <img src="/img/icons/explore.svg" alt="data" />
                      <p className={styles.header__menu_text}>{t("explore")}</p>
                    </div>
                    <img src="/img/icons/chevron-right.svg" alt="data" />
                  </div>
                  <div
                    className={styles.header__menu_item}
                    onClick={() => {
                      setMenuOn(false);
                      navigate("/blog");
                    }}
                  >
                    <div className={styles.header__menu_item__container}>
                      <img src="/img/icons/news.svg" alt="data" />
                      <p className={styles.header__menu_text}>{t("news")}</p>
                    </div>
                    <img src="/img/icons/chevron-right.svg" alt="data" />
                  </div>
                  {userInfo && (
                    <>
                      <div className="header__menu-item flex__column_auto">
                        <div className="header__menu-item--container colum_start">
                          <div className={styles.admin__menu_container}>
                            <img src="/img/icons/wallet.svg" alt="data" />
                            <p className={styles.header__menu_text}>
                              {t("my assets")}
                            </p>
                          </div>
                          <div className={styles.header__new_article}>
                            <div
                              className={styles.admin__menu_item}
                              onClick={() => {
                                setMenuOn(false);
                                navigate("/wallet");
                              }}
                            >
                              <div className={styles.sub__menu_item}>
                                <img
                                  src="/img/wallet/overview.svg"
                                  alt="data"
                                />
                                <p className={styles.admin__menu_text}>
                                  {t("overview")}
                                </p>
                              </div>
                              <div>
                                <img
                                  src="/img/icons/chevron-right-small.svg"
                                  className={styles.sub__menu_img}
                                  alt="data"
                                />
                              </div>
                            </div>
                            <div
                              className={styles.admin__menu_item}
                              onClick={() => {
                                setMenuOn(false);
                                navigate("/wallet/digital_items");
                              }}
                            >
                              <div className={styles.sub__menu_item}>
                                <img src="/img/wallet/NFTs.svg" alt="data" />
                                <p className={styles.admin__menu_text}>
                                  {t("tickets & items")}
                                </p>
                              </div>
                              <div>
                                <img
                                  src="/img/icons/chevron-right-small.svg"
                                  className={styles.sub__menu_img}
                                  alt="data"
                                />
                              </div>
                            </div>
                            <div
                              className={styles.admin__menu_item}
                              onClick={() => {
                                setMenuOn(false);
                                navigate("/wallet/send_receive");
                              }}
                            >
                              <div className={styles.sub__menu_item}>
                                <img src="/img/wallet/send.svg" alt="data" />
                                <p className={styles.admin__menu_text}>
                                  {t("send & receive")}
                                </p>
                              </div>
                              <div>
                                <img
                                  src="/img/icons/chevron-right-small.svg"
                                  className={styles.sub__menu_img}
                                  alt="data"
                                />
                              </div>
                            </div>
                            <div
                              className={styles.admin__menu_item}
                              onClick={() => {
                                setMenuOn(false);
                                navigate("/wallet/transaction");
                              }}
                            >
                              <div className={styles.sub__menu_item}>
                                <img
                                  src="/img/wallet/transaction.svg"
                                  alt="data"
                                />
                                <p className={styles.admin__menu_text}>
                                  {t("transactions")}
                                </p>
                              </div>
                              <div>
                                <img
                                  src="/img/icons/chevron-right-small.svg"
                                  className={styles.sub__menu_img}
                                  alt="data"
                                />
                              </div>
                            </div>
                            <div
                              className={styles.admin__menu_item}
                              onClick={() => {
                                setMenuOn(false);
                                navigate("/wallet/currencies");
                              }}
                            >
                              <div className={styles.sub__menu_item}>
                                <img
                                  src="/img/wallet/currencies.svg"
                                  alt="data"
                                />
                                <p className={styles.admin__menu_text}>
                                  {t("currencies")}
                                </p>
                              </div>
                              <div>
                                <img
                                  src="/img/icons/chevron-right-small.svg"
                                  className={styles.sub__menu_img}
                                  alt="data"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.header__menu_item}
                        onClick={() => {
                          setMenuOn(false);
                          navigate("/liked");
                        }}
                      >
                        <div className={styles.header__menu_item__container}>
                          <img
                            src="/img/icons/liked_white.svg"
                            alt="data"
                            width={24}
                            height={24}
                          />
                          <p className={styles.header__menu_text}>
                            {t("liked")}
                          </p>
                        </div>
                        <img src="/img/icons/chevron-right.svg" alt="data" />
                      </div>
                      <div
                        className={styles.header__menu_item}
                        onClick={() => {
                          setMenuOn(false);
                          navigate("/author");
                        }}
                      >
                        <div className={styles.header__menu_item__container}>
                          <img
                            src="/img/icons/user.svg"
                            alt="data"
                            width={24}
                            height={24}
                          />
                          <p className={styles.header__menu_text}>
                            {t("profile settings")}
                          </p>
                        </div>
                        <img src="/img/icons/chevron-right.svg" alt="data" />
                      </div>
                      {userInfo.user.email ===
                        process.env.REACT_APP_PERMISSION && (
                        <div
                          className={styles.header__menu_item}
                          onClick={() => {
                            setMenuOn(false);
                            navigate("/permissions");
                          }}
                        >
                          <div className={styles.header__menu_item__container}>
                            <img
                              src="/img/icons/check.svg"
                              alt="data"
                              width={24}
                              height={24}
                            />
                            <p className={styles.header__menu_text}>
                              {t("permission setting")}
                            </p>
                          </div>
                          <img src="/img/icons/chevron-right.svg" alt="data" />
                        </div>
                      )}
                      {userInfo.user.user_type === "ADMIN" && (
                        <div className="header__menu-item flex__column_auto">
                          <div className="header__menu-item--container colum_start">
                            <div className={styles.admin__menu_container}>
                              <img src="/img/icons/settings.svg" alt="data" />
                              <p className={styles.header__menu_text}>
                                {t("admin menu")}
                              </p>
                            </div>
                            <div className={styles.header__new_article}>
                              <div
                                className={styles.admin__menu_item}
                                onClick={() => {
                                  setMenuOn(false);
                                  navigate("/admin/users");
                                }}
                              >
                                <div className={styles.sub__menu_item}>
                                  <img src="/img/icons/users.svg" alt="data" />
                                  <p className={styles.admin__menu_text}>
                                    {t("see users")}
                                  </p>
                                </div>
                                <div>
                                  <img
                                    src="/img/icons/chevron-right-small.svg"
                                    className={styles.sub__menu_img}
                                    alt="data"
                                  />
                                </div>
                              </div>
                              <div
                                className={styles.admin__menu_item}
                                onClick={() => {
                                  setMenuOn(false);
                                  navigate("/admin/report");
                                }}
                              >
                                <div className={styles.sub__menu_item}>
                                  <img
                                    src="/img/icons/bar-chart-2.svg"
                                    alt="data"
                                  />
                                  <p className={styles.admin__menu_text}>
                                    {t("admin report")}
                                  </p>
                                </div>
                                <div>
                                  <img
                                    src="/img/icons/chevron-right-small.svg"
                                    className={styles.sub__menu_img}
                                    alt="data"
                                  />
                                </div>
                              </div>
                              <div
                                className={styles.admin__menu_item}
                                onClick={() => {
                                  setMenuOn(false);
                                  navigate("/track/report");
                                }}
                              >
                                <div className={styles.sub__menu_item}>
                                  <img
                                    src="/img/icons/bar-chart-2.svg"
                                    alt="data"
                                  />
                                  <p className={styles.admin__menu_text}>
                                    {t("track report")}
                                  </p>
                                </div>
                                <div>
                                  <img
                                    src="/img/icons/chevron-right-small.svg"
                                    className={styles.sub__menu_img}
                                    alt="data"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(userInfo.user.user_type === "ADMIN" ||
                        (userInfo.user.user_type === "BUSINESS" &&
                          userInfo.user.business_allow)) && (
                        <div
                          className={styles.header__menu_item}
                          onClick={() => {
                            setMenuOn(false);
                            navigate("/sales/overview");
                          }}
                        >
                          <div className={styles.header__menu_item__container}>
                            <img
                              src="/img/icons/bar-chart-2.svg"
                              alt="data"
                              width={24}
                              height={24}
                            />
                            <p className={styles.header__menu_text}>
                              {t("sales reports")}
                            </p>
                          </div>
                          <img src="/img/icons/chevron-right.svg" alt="data" />
                        </div>
                      )}
                      {userInfo.user.user_type === "BUSINESS" &&
                        userInfo.user.business_allow && (
                          <div
                            className={styles.header__menu_item}
                            onClick={() => {
                              setMenuOn(false);
                              navigate("/admin/report");
                            }}
                          >
                            <div
                              className={styles.header__menu_item__container}
                            >
                              <img
                                src="/img/icons/bar-chart-2.svg"
                                alt="data"
                                width={24}
                                height={24}
                              />
                              <p className={styles.header__menu_text}>
                                {t("admin report")}
                              </p>
                            </div>
                            <img
                              src="/img/icons/chevron-right.svg"
                              alt="data"
                            />
                          </div>
                        )}
                    </>
                  )}
                </div>
                {/* Sign buttons */}
                <div className={styles.header__sign_out}>
                  {userInfo ? (
                    <button
                      className="sign__btn-second"
                      type="button"
                      onClick={() => {
                        handleSignout();
                        setMenuOn(false);
                        navigate("/");
                      }}
                    >
                      <img src="/img/icons/log-out-white.svg" alt="data" />
                      {t("sign out")}
                    </button>
                  ) : (
                    <>
                      <button
                        className="sign__btn"
                        type="button"
                        onClick={() => {
                          setMenuOn(false);
                          navigate("/signin");
                        }}
                      >
                        <img src="/img/icons/log_in.svg" alt="data" />
                        {t("sign in")}
                      </button>
                      <div className="sign__separated_container">
                        <div className="sign__separated-line"></div>
                        <span className="sign__text">
                          {t("don't have an account?")}&nbsp;
                        </span>
                        <div className="sign__separated-line"></div>
                      </div>
                      <button
                        className="sign__btn-second"
                        type="button"
                        onClick={() => {
                          setMenuOn(false);
                          navigate("/signup");
                        }}
                      >
                        <img src="/img/icons/log_in.svg" alt="data" />
                        {t("personal")}
                      </button>
                      <button
                        className="sign__btn-second"
                        type="button"
                        onClick={() => {
                          setMenuOn(false);
                          navigate("/business-signup");
                        }}
                      >
                        <img src="/img/icons/log_in.svg" alt="data" />
                        {t("business")}
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
            {searchOn && (
              <div className={styles.header__search_mobile}>
                <div className={styles.header__search_top}>
                  <div className="header__search">
                    <input
                      type="text"
                      placeholder={t("search Backstage marketplace")}
                      onChange={onSearch}
                      onFocus={() => navigate("/explorer")}
                      value={searchStr}
                    />
                    <button
                      className={styles.search__btn_container}
                      type="button"
                    >
                      <svg
                        fill="#642d2d"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
                      </svg>
                    </button>
                  </div>
                  <button onClick={() => setSearchOn(false)}>
                    <img src="/img/icons/close-icon.svg" alt="data" />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default withCookies(Header);
