import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { ICollection } from "../../../utils/interface";
import { deleteCollectionById } from "../../../api/event";
import { getAllCollections } from "../../../api/event";
import Pagination from "../../../components/pagination";
import Collection from "../../../components/collection";
import FilterSort from "../../../components/filter/FilterSort";

const PageExplorer = () => {
  const isMobile = useMediaQuery("(max-width: 450px)");
  const isMiddle = useMediaQuery("(max-width: 1235px)");
  const isSmall = useMediaQuery("(max-width: 932px)");
  // const [collections, setCollections] = useState<ICollection[]>(
  //   [] as ICollection[]
  // );
  const [filterColls, setFilterColls] = useState<ICollection[]>(
    [] as ICollection[]
  );
  const [pageIndex, setPageIndex] = useState(0);
  const [itemIndex, setItemIndex] = useState(0);
  const [pageCount, setPageCount] = useState(8);
  const [currentTag, setCurrentTag] = useState("all");
  const [filterTag, setFilterTag] = useState("all");
  const [currentBtn, setCurrentBtn] = useState("");
  const [sort, setSort] = useState(0);
  const {
    scrollItem,
    setLoading,
    searchStr,
    addToast,
    allCollections,
    setAllCollections,
  } = useAppContext();
  const { t } = useTranslation();

  const leftClick = () => {
    if (itemIndex > 0) {
      let _itemIndex = itemIndex;
      _itemIndex--;
      setItemIndex(_itemIndex);
    }
  };

  const rightClick = () => {
    if ((itemIndex + 1) * pageCount < filterColls.length) {
      let _itemIndex = itemIndex;
      _itemIndex++;
      setItemIndex(_itemIndex);
    }
  };

  const handleDeleteCollection = async (collectionId: string) => {
    deleteCollectionById(collectionId)
      .then((res) => {
        if (res.success) {
          let _collections = allCollections;
          _collections.forEach((element: ICollection, index: number) => {
            if (element.id === collectionId) {
              _collections.splice(index, 1);
            }
          });
          setAllCollections([..._collections]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sortCollections = (collections: ICollection[]) => {
    // const categoryOrder = [
    //   "festival", "concert", "club", "show", "meet & greet",
    //   "awards", "experience", "sport", "hospitality", "platform",
    //   "art", "store", "cirque"
    // ];

    let temp: ICollection[] = [];
    if (currentTag === "all") {
      temp = [...collections];
    } else {
      temp = collections.filter(filterCollections);
    }
    let res = temp.filter((collection) =>
      collection.name.toLowerCase().includes(searchStr?.trim().toLowerCase() as string)
    );
    let _res = res.filter((collection) =>
      filterTag === "all"
        ? collection
        : collection.category.toLowerCase().includes(filterTag)
    );
    // _res.sort((a, b) => {
    //   return categoryOrder.indexOf(a.category.toLowerCase()) - categoryOrder.indexOf(b.category.toLowerCase());
    // });
    setFilterColls([..._res]);
  };

  const filterCollections = (collection: ICollection) => {
    if (
      collection.categories !== null &&
      JSON.parse(collection.categories).indexOf(currentTag) !== -1
    ) {
      return collection;
    }
  };

  const preCollections = (array: never[]) => {
    setAllCollections(
      array.sort(
        (item1: ICollection, item2: ICollection) =>
          new Date(item2.createdAt).getTime() -
          new Date(item1.createdAt).getTime()
      )
    );
  };

  useEffect(() => {
    (scrollItem.current as any).scrollIntoView({ behavior: "smooth" });
  }, [itemIndex, pageIndex, scrollItem]);

  useEffect(() => {
    if (allCollections.length === 0) {
      setLoading(true);
      getAllCollections()
        .then((res) => {
          if (res.success) {
            preCollections(res.collections);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          addToast("Network error occured", {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortCollections(allCollections);
    setItemIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTag, filterTag, allCollections]);

  useEffect(() => {
    sortCollections(allCollections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStr]);

  useEffect(() => {
    setItemIndex(0);
    if (isSmall) {
      setPageCount(4);
    } else if (isMiddle) {
      setPageCount(9);
    } else {
      setPageCount(8);
    }
  }, [isMiddle, isSmall]);

  return (
    <div className="explorer__container">
      <div className="explorer__top">
        <div>
          <p className="explorer__top-title">{t("explore")}</p>
        </div>
        <div className="explorer__top-main">
          <div className="explorer__top-tags">
            <button
              className={
                currentTag === "all"
                  ? "category__active--tag"
                  : "category__inactive--tag"
              }
              onClick={() => setCurrentTag("all")}
            >
              {t("all")}
            </button>
            <button
              className={
                currentTag === "Category1"
                  ? "category__active--tag"
                  : "category__inactive--tag"
              }
              onClick={() => setCurrentTag("Category1")}
            >
              {t("tickets")}
            </button>
            <button
              className={
                currentTag === "Category2"
                  ? "category__active--tag"
                  : "category__inactive--tag"
              }
              onClick={() => setCurrentTag("Category2")}
            >
              {t("digital arts")}
            </button>
            <button
              className={
                currentTag === "Category3"
                  ? "category__active--tag"
                  : "category__inactive--tag"
              }
              onClick={() => setCurrentTag("Category3")}
            >
              {t("services")}
            </button>
          </div>
          <FilterSort
            currentBtn={currentBtn}
            currentTag={filterTag}
            sort={sort}
            setCurrentBtn={setCurrentBtn}
            setCurrentTag={setFilterTag}
            setSort={setSort}
          />
        </div>
      </div>
      <div className="grid-container" style={{ margin: isMiddle ? "auto" : 0 }}>
        {filterColls.map(
          (collection: ICollection, i) =>
            i >= (pageIndex + itemIndex) * pageCount &&
            i < (pageIndex + itemIndex + 1) * pageCount && (
              <div key={`explorer_event_${i}`}>
                <Collection
                  key={collection.id}
                  collection={collection}
                  handleDelete={handleDeleteCollection}
                />
              </div>
            )
        )}
        {isMobile && pageIndex > 0 && (
          <button onClick={() => setPageIndex(0)} className="arrow_up_button">
            <LazyLoadImage src="/img/icons/arrow-upward.svg" alt="data" />
          </button>
        )}
      </div>

      <Pagination
        items={filterColls}
        setItemIndex={setItemIndex}
        pageIndex={pageIndex}
        itemIndex={itemIndex}
        leftClick={leftClick}
        rightClick={rightClick}
        count={pageCount}
      />
      <div className="bottom_block"></div>
    </div>
  );
};

export default PageExplorer;
